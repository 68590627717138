import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Container from 'components/organisms/Container';
import Button from 'components/atoms/Button';
import Seo from 'components/atoms/Seo';

const NotFoundPage = ({
  data: {
    contentful404Page: { background, heading, subheading, homeButton },
  },
}) => (
  <div className="page404">
    <Seo title="404: Nie odnaleziono" />
    <Container>
      <div className="page404__content-wrapper">
        <h1 className="page404__heading">{heading}</h1>
        <h2 className="page404__subheading">{subheading}</h2>
        <Button
          variant="primary-outline"
          buttonLabel={homeButton.buttonLabel}
          reference={homeButton.reference}
        />
      </div>
    </Container>
    <GatsbyImage
      className="page404__background"
      image={background.image.gatsbyImageData}
      alt={background.altText}
    />
  </div>
);

export const query = graphql`
  {
    contentful404Page {
      background {
        altText
        image {
          gatsbyImageData(formats: WEBP)
        }
      }
      heading
      subheading
      homeButton {
        buttonLabel
        reference {
          ... on ContentfulHomePage {
            slug
          }
        }
      }
    }
  }
`;

export default NotFoundPage;
